import { Input } from '@/components/atoms/Input/Input';
import {
  Switcher,
  SwitcherOptionType,
} from '@/components/atoms/Switcher/Switcher';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { EfficiencyRates } from '@/types';
import { BoltIcon } from '@heroicons/react/24/solid';
import { useCallback, useMemo, useState } from 'react';
import { MakeIcon } from '@/components/atoms/MakeIcons/MakeIcon';
import Accordion, { AccordionProps } from '../Accordion';
import { DropdownMenuItemVariants } from '../DropdownMenuItem';
import FormSelect from '../FormSelect';

export const enum CarTypes {
  'Truck' = 'Truck',
  'SUV' = 'SUV',
  'Sedan' = 'Sedan',
}

// Mile / Gallon
export const GasRates = {
  Truck: '18',
  SUV: '20',
  Sedan: '29',
};

// kWh / 100 miles
export const EvRates = {
  Truck: '49',
  SUV: '28',
  Sedan: '25.4',
};

const CAR_TYPE_OPTIONS: SwitcherOptionType[] = [
  {
    text: CarTypes.Truck,
    subText: '18 MPG',
    value: CarTypes.Truck,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Truck} width={'59px'} height={'26px'} />,
    },
  },
  {
    text: CarTypes.SUV,
    subText: '20 MPG',
    value: CarTypes.SUV,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Suv} width={'59px'} height={'26px'} />,
    },
  },
  {
    text: CarTypes.Sedan,
    subText: '29 MPG',
    value: CarTypes.Sedan,
    icon: {
      position: 'top',
      icon: <Icon iconId={IconIds.Sedan} width={'59px'} height={'26px'} />,
    },
  },
];

const TimePeriodOptions = [
  {
    text: '1 year',
    value: '1',
  },
  {
    text: '2 years',
    value: '2',
  },
  {
    text: '3 years',
    value: '3',
  },
  {
    text: '4 years',
    value: '4',
  },
  {
    text: '5 years',
    value: '5',
  },
];

export const getCostPerYear = (
  mileage: string | number,
  cost: string | number,
  efficiency: string | number
) => {
  return Math.floor((Number(mileage) * Number(cost)) / Number(efficiency));
};

interface GasVsElectricCosts {
  mileagePerYear?: string;
  gasolineCost?: string;
  electricityCost?: string;
}

export const DEFAULT_COSTS: Readonly<Required<GasVsElectricCosts>> = {
  mileagePerYear: '10000',
  gasolineCost: '3.90',
  electricityCost: '0.16',
};

interface GVECalculatorProps
  extends Pick<AccordionProps, 'expanded' | 'setExpanded'>,
    GasVsElectricCosts {
  isHomePage?: boolean;
  make?: string;
  model?: string;
  efficiencyRates?: EfficiencyRates;
  onChangeFuelPeriod?: (value: string) => void;
  onChangeAnnualMiles?: (value: string) => void;
  onChangeGasPerGallon?: (value: string) => void;
  onChangeElectricityCost?: (value: string) => void;
}

export const GVECalculator = ({
  isHomePage = false,
  expanded = false,
  setExpanded,
  make,
  model,
  efficiencyRates,
  mileagePerYear: initialMileagePerYear,
  gasolineCost: initialGasolineCost,
  electricityCost: initialElectricityCost,
  onChangeFuelPeriod,
  onChangeAnnualMiles,
  onChangeGasPerGallon,
  onChangeElectricityCost,
}: GVECalculatorProps) => {
  const [years, setYears] = useState('5');
  const [mileagePerYear, setMileagePerYear] = useState(
    initialMileagePerYear || DEFAULT_COSTS.mileagePerYear
  );
  const [gasolineCost, setGasolineCost] = useState(
    initialGasolineCost || DEFAULT_COSTS.gasolineCost
  );
  const [electricityCost, setElectricityCost] = useState(
    initialElectricityCost || DEFAULT_COSTS.electricityCost
  );
  const [carType, setCarType] = useState(CarTypes.Sedan);

  const { totalSavings, evWidth, gasCost, evCost } = useMemo(() => {
    const gasC = getCostPerYear(
      mileagePerYear,
      gasolineCost,
      efficiencyRates ? efficiencyRates.gas_rate : GasRates[carType]
    );
    const electricRate =
      100 /
      (efficiencyRates ? efficiencyRates.ev_rate : Number(EvRates[carType]));
    const evC = getCostPerYear(mileagePerYear, electricityCost, electricRate);
    const calculatedTotalSavings = (gasC - evC) * Number(years);
    const calculatedEvWidth = String((evC / gasC) * 100);
    return {
      totalSavings: calculatedTotalSavings,
      evWidth: calculatedEvWidth,
      gasCost: gasC,
      evCost: evC,
    };
  }, [
    efficiencyRates,
    carType,
    electricityCost,
    gasolineCost,
    mileagePerYear,
    years,
  ]);

  const handleChangeYears = useCallback(
    (newYearValue: string) => {
      if (onChangeFuelPeriod) {
        onChangeFuelPeriod(newYearValue);
      }
      setYears(newYearValue);
    },
    [onChangeFuelPeriod]
  );

  const handleChangeMileagePerYear = useCallback(
    (value: string) => {
      if (onChangeAnnualMiles) {
        onChangeAnnualMiles(value);
      }
      setMileagePerYear(value);
    },
    [onChangeAnnualMiles]
  );

  const handleChangeGasPerGallon = useCallback(
    (value: string) => {
      if (onChangeGasPerGallon) {
        onChangeGasPerGallon(value);
      }
      setGasolineCost(value);
    },
    [onChangeGasPerGallon]
  );

  const handleElectricityCostChange = useCallback(
    (value: string) => {
      if (onChangeElectricityCost) {
        onChangeElectricityCost(value);
      }
      setElectricityCost(value);
    },
    [onChangeElectricityCost]
  );

  return (
    <div
      className={`flex h-full w-full flex-col rounded-small border border-neutral-200 ${isHomePage ? 'rounded-small shadow-xl l:flex-row' : 'ml:flex-row'} `}
    >
      <div
        className={`flex w-full flex-col gap-s rounded-t-small bg-neutral-0 px-xl py-xl ml:rounded-l-small ml:rounded-tr-none ${isHomePage ? 'ml:rounded-tr-small' : 'ml:w-1/2 l:w-1/3'}`}
      >
        <div className="order-1 text-h3SemiBold">Cost to drive</div>

        {efficiencyRates && (
          <div className="order-2 flex gap-l pb-s">
            <div className="h-2xl w-2xl">
              {make && (
                <MakeIcon
                  aria-label={`${make} logo`}
                  width={32}
                  height={32}
                  make={make}
                />
              )}
            </div>
            <div className="flex flex-col text-neutral-800">
              <div className="text-body2Regular">{make}</div>
              <div className="text-body2Light">{model}</div>
            </div>
          </div>
        )}

        <div className="order-2">
          <FormSelect
            label="Time period"
            options={TimePeriodOptions}
            value={[years]}
            onChange={([newYear]) => {
              handleChangeYears(newYear);
            }}
            variant={DropdownMenuItemVariants.SingleSelect}
          />
        </div>
        <div className="order-4 pt-s m:order-3">
          <Accordion
            title={'Edit vehicle efficiency'}
            icon={{ id: 'chevron' }}
            buttonClassName="text-blue-medium hover:text-blue-dark hover:underline"
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <div className="flex flex-col gap-s pb-s">
              <Input
                aria-label="Mileage per year"
                type="number"
                value={mileagePerYear}
                onChange={handleChangeMileagePerYear}
                label="Mileage per year"
                float={0}
                append="miles"
                id={'mileagePerYear'}
              />

              <Input
                aria-label="Gasoline cost per gallon"
                type="number"
                value={gasolineCost}
                onChange={handleChangeGasPerGallon}
                label="Gasoline cost per gallon"
                append="/gallon"
                prepend="$"
                id={'gasolineCost'}
              />

              <Input
                aria-label="Electricity cost per kWh"
                type="number"
                value={electricityCost}
                onChange={handleElectricityCostChange}
                label="Electricity cost"
                append="/kWh"
                prepend="$"
                id={'electricityCost'}
              />
            </div>
          </Accordion>
        </div>

        {!efficiencyRates && (
          <div className="order-3 pt-s m:order-4 m:pt-0">
            <Switcher
              aria-label="Car type"
              options={CAR_TYPE_OPTIONS}
              value={carType}
              size="content"
              onChange={(e) => {
                setCarType(e as CarTypes);
              }}
            />
          </div>
        )}
      </div>
      <div
        className={`flex w-full flex-grow flex-col gap-xl rounded-b-small bg-neutralsGrey-200 px-xl pb-xl pt-l ${isHomePage ? 'ml:rounded-b-small ml:rounded-t-none l:rounded-bl-none l:rounded-tr-small' : 'ml:w-1/2 ml:rounded-r-small ml:rounded-bl-none l:w-2/3'}`}
      >
        <section className="order-3 m:order-1">
          <span className="whitespace-nowrap pb-xl text-body1Medium text-neutral-900">
            Savings over {years} year{years === '1' ? '' : 's'}:{' '}
            <span className="text-h4SemiBold">${totalSavings}</span>
          </span>
        </section>

        <section className="order-1 m:order-2">
          <div className="flex pb-s">
            <div className="relative mr-s h-[24px] w-[24px] rounded-[100%] bg-neutral-0">
              <BoltIcon className="absolute p-xs text-brandElectric" />
            </div>
            <div className="text-body1Medium text-neutral-900">
              Electricity cost per year
            </div>
          </div>
          <div className="flex h-[54px] w-full">
            <div
              className="min-w-max rounded-r-small bg-gradient-to-r from-darkBrand-start to-darkBrand-end px-s py-l text-neutral-0"
              style={{
                width: `${evWidth}%`,
              }}
            >
              <span className="text-body1Medium">
                ${evCost}
                <span className="text-body2Regular">/ year</span>
              </span>
            </div>
          </div>
        </section>

        <section className="order-2 m:order-3">
          <div className="flex pb-s">
            <Icon
              iconId={IconIds.Gas}
              className="mr-s flex h-[24px] w-[24px]"
            />
            <div className="text-body1Medium text-neutral-900">
              Gasoline cost per year
            </div>
          </div>
          <div className="flex h-[54px] w-full">
            <div className="flex w-full rounded-r-small bg-neutral-900 px-s py-l text-white">
              <span className="text-body1Medium">
                ${gasCost}
                <span className="text-body2Regular">/ year</span>
              </span>
            </div>
          </div>
        </section>

        {!isHomePage && (
          <section className="order-4 flex flex-grow items-end">
            <ToolTip
              aria-label="Efficiency rates and calculations disclosure tooltip"
              hoverElement={
                <div className="text-microMedium underline">
                  View calculations
                </div>
              }
            >
              {efficiencyRates && (
                <div>
                  <p>
                    This comparison evaluates average EV{' '}
                    {efficiencyRates.vehicle_style}s and gasoline{' '}
                    {efficiencyRates.vehicle_style}s based on class and
                    features. Efficiency assessments are guided by EPA
                    standards, factoring in 45% highway and 55% city driving for{' '}
                    {Intl.NumberFormat('en-US').format(Number(mileagePerYear))}{' '}
                    annual miles. Costs are based on national averages: $
                    {Number(gasolineCost).toFixed(2)} per gallon of gasoline and
                    ${Number(electricityCost).toFixed(2)} per kWh for
                    electricity. Actual savings may vary due to market changes
                    and driving conditions.
                  </p>
                  <p className="pt-s">
                    Average EV {efficiencyRates.vehicle_style} consumption
                    rating = {efficiencyRates.ev_rate} kWh/100mi
                  </p>
                  <p>
                    Average Gasoline {efficiencyRates.vehicle_style} consumption
                    rating = {efficiencyRates.gas_rate} MPG.
                  </p>
                </div>
              )}
              {!efficiencyRates && (
                <div>
                  <p>
                    {carType === CarTypes.Truck &&
                      'This comparison features the 2023 Ford F-150 Lightning 4WD and the 2023 Ford F150 Pickup 4WD, selected for their class and features. Efficiency evaluations follow EPA standards, considering 45% highway and 55% city driving over 10,000 annual miles. Costs are based on national averages: $3.60 per gallon of gasoline and $0.16 per kWh for electricity. Actual savings for trucks may vary due to market changes and driving conditions.'}
                    {carType === CarTypes.SUV &&
                      'This comparison evaluates average EV SUVs and gasoline SUVs based on class and features. Efficiency assessments are made according to EPA standards, with 45% highway and 55% city driving over 10,000 annual miles. Costs reflect national averages: $3.60 per gallon for gasoline and $0.16 per kWh for electricity. Actual savings for SUVs may vary due to market fluctuations and driving conditions.'}
                    {carType === CarTypes.Sedan &&
                      'This comparison uses the 2023 Model 3 Base AWD and 2023 Toyota Camry LE/SE, selected based on class and features. Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for 10,000 annual miles. Costs are based on national averages: $3.60 per gallon of gasoline and $0.16 per kWh for electricity. Actual savings may vary due to market changes and driving conditions.'}
                  </p>
                  {carType === CarTypes.Truck && (
                    <>
                      <p className="pt-s">
                        2023 Ford F-150 Lightning 4WD consumption rating ={' '}
                        {EvRates.Truck} kWh/100mi.
                      </p>
                      <p>
                        2023 Ford F150 Pickup 4WD consumption rating ={' '}
                        {GasRates.Truck} MPG.
                      </p>
                    </>
                  )}
                  {carType === CarTypes.SUV && (
                    <>
                      <p className="pt-s">
                        Average EV SUV consumption rating = {EvRates.SUV}{' '}
                        kWh/100mi
                      </p>
                      <p>
                        Average Gasoline SUV consumption rating = {GasRates.SUV}{' '}
                        MPG.
                      </p>
                    </>
                  )}
                  {carType === CarTypes.Sedan && (
                    <>
                      <p className="pt-s">
                        2023 Model 3, Base AWD consumption rating ={' '}
                        {EvRates.Sedan} kWh/100mi.
                      </p>
                      <p>
                        2023 Toyota Camry LE/SE consumption rating ={' '}
                        {GasRates.Sedan} MPG
                      </p>
                    </>
                  )}
                </div>
              )}
            </ToolTip>
          </section>
        )}
      </div>
    </div>
  );
};
